<template>
  <div style="width: 100%;">
    
    <v-row dense no-gutters>
      <v-col cols="12">
        <Table
          :key="tableKey"
          :items="logs"
          :loading="loading"
          @fetchLogs="fetchLogs($event)"
        ></Table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Table from './LogsInstancesDatatable'
import {
  getInstancesUsingGET as getLogs
} from '@/utils/api'
import { differenceInMilliseconds, differenceInSeconds, parseISO } from 'date-fns'
import { mapActions } from 'vuex'

export default {
  components: {
    Table
  },
  data() {
    return {
      err: '',
      success: '',
      tableKey: 0,
      loading: false,
      logs: { items: [], meta: {} }
    }
  },
  computed: {},
  created() {
    if (this.$route.params && this.$route.params.message) {
      this.addSnackbar({
        message: this.$route.params.message,
        timeout: 5000,
        color: 'success'
      })
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),

    fetchLogs(options) {
      this.err = ''

      const obj = {}

      if (options) {
        if (options.name && options.name.length > 1) obj.name = options.name
        if (options.options.itemsPerPage !== -1) {
          obj.page = options.resetPage ? 1 : options.options.page || 1
          obj.size = options.options.itemsPerPage
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      if (options?.options?.sortDesc && options.options.sortDesc.length > 0) obj.orderDirection = options.options.sortDesc[0] ? 'DESC' : 'ASC'

      if (options && options.options && options.options.sortBy && options.options.sortBy.length > 0) {
        obj.orderField = options.options.sortBy[0]
      }

      if (obj.orderField === 'ipAddressesArray') {
        obj.orderField = 'ipAddress'
      }

      this.loading = true
      getLogs(obj)
        .then((res) => {
          const fullRes = {
            items: res.data.data.items.map((x) => {
              return {
                ...x,
                ipAddressesArray: x.ipAddress ? x.ipAddress.split(',').map((y) => y.trim()) : []
              }
            }),
            meta: res.data.data.meta
          }

          this.logs = options.filterInMilliseconds ? { items: fullRes.items.filter((x) => differenceInMilliseconds(parseISO(new Date().toISOString()), parseISO(x.heartbeat)) < options.filterInMilliseconds), meta: fullRes.meta } : fullRes
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.addSnackbar({
            message: error,
            timeout: 5000,
            color: 'error'
          })          
          console.log(error)
        })
    }
  }
}
</script>
